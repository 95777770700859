import axios from 'axios';
import handleError from './format-error';
import { handleValidationError } from '../validation/validate';

const VOLUNTEERS_BASE = '/volunteers';

const getVolunteersStats = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${VOLUNTEERS_BASE}/stats`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const signUpNoSSO = async (form, { options } = {}) => {
  try {
    const { data } = await axios.post(
      `${VOLUNTEERS_BASE}/${form.userId}/signup-no-sso`,
      form
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    if (err.statusCode === 422) {
      const { inner } = handleValidationError(error.response.data);
      return { error: err, inner };
    }
    return { error: err };
  }
};

export { getVolunteersStats, signUpNoSSO };
