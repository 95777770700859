import { css } from '@emotion/react';
import colors from './colors';

const style = css`
  pre {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1em;
  }

  code,
  kbd,
  samp {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', sans-serif;
    word-wrap: break-word;
  }

  /* to override body margin from antd */
  .sb-show-main.sb-main-padded {
    margin: 0;
    padding: 0;
  }

  /* to override elipsis on dropdown options from antd */
  .ant-select-item-option-content {
    white-space: normal;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
  }

  *,
  *:after,
  *:before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'Nunito Sans', sans-serif;
  }

  *:focus {
    outline: none;
  }

  *:focus-visible {
    outline: #0061ce solid 2px;
  }

  *:active {
    outline: none;
  }

  .anticon {
    line-height: 100%;
  }

  .ant-select-item.ant-select-item-group {
    color: ${colors.teal};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 143%;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default style;
