import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import setMargin from '../../helpers/set-margin';

const commonStyle = ({ theme, color, caps, ta, display, ...props }) => `
display: ${display || 'inline-block'};
font-style: normal;
font-weight: 900;
letter-spacing: 0.2px;
color: ${theme.colors[color] || color || theme.colors.black};
text-transform: ${caps ? 'uppercase' : 'initial'};
text-align: ${ta || 'left'};
`;

const h1Style = `
font-size: 56.9px;
line-height: 64px;
`;

const h2Style = `
font-size: 45.5px;
line-height: 53px;
`;

const h3Style = `
font-size: 36.4px;
line-height: 44px;
`;

const h4Style = `
font-size: 29.2px;
line-height: 37px;
`;

const h5Style = `
font-size: 23.3px;
line-height: 31px;
`;

const h6Style = `
font-size: 18.66px;
line-height: 26px;
`;

const styleMap = {
  h1: h1Style,
  h2: h2Style,
  h3: h3Style,
  h4: h4Style,
  h5: h5Style,
  h6: h6Style,
};

export const H1 = styled.h1`
  ${setMargin};
  ${commonStyle};
  ${({ override }) => styleMap[override] || h1Style};
  ${({ theme }) => theme.media.mobile} {
    ${h2Style};
  }
`;

export const H2 = styled.h2`
  ${setMargin};
  ${commonStyle};
  ${({ override }) => styleMap[override] || h2Style};
  ${({ theme }) => theme.media.mobile} {
    ${h3Style};
  }
`;

export const H3 = styled.h3`
  ${setMargin};
  ${commonStyle};
  ${({ override }) => styleMap[override] || h3Style};
  ${({ theme }) => theme.media.mobile} {
    ${h4Style};
  }
`;

export const H4 = styled.h4`
  ${setMargin};
  ${commonStyle};
  ${({ override }) => styleMap[override] || h4Style};
  ${({ theme }) => theme.media.mobile} {
    ${h5Style};
  }
`;

export const H5 = styled.h5`
  ${setMargin};
  ${commonStyle};
  ${({ override }) => styleMap[override] || h5Style};
  ${({ theme }) => theme.media.mobile} {
    ${h6Style};
  }
`;

export const H6 = styled.h6`
  ${setMargin};
  ${({ override }) => styleMap[override] || h6Style};
  ${commonStyle};
`;

export const BodyL = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: normal;
  font-size: 23.3px;
  line-height: 32px;
`;
export const BodyR = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: normal;
  font-size: 18.66px;
  line-height: 25px;
`;
export const Body16 = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;
export const BodySM = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : 'normal')} !important;
  font-size: 14.9px;
  line-height: 21.31px;
`;
export const BodyXSM = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 21.31px;
`;
export const BodySMItalic = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: italic;
  font-weight: 200;
  font-size: 12px;
  line-height: 21.31px;
`;
export const BodyLB = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: bold;
  font-size: 23.3px;
  line-height: 135%;
`;
export const BodyB = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: bold;
  font-size: 18.66px;
  line-height: 143%;
`;
export const Body16B = styled.p`
  ${setMargin};
  ${commonStyle};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 143%;
  text-decoration-line: ${({ underline }) =>
    underline ? 'underline' : 'none'};
`;

export const Link = styled(RouterLink)`
  ${setMargin};
  ${commonStyle};
  color: ${({ theme, color }) =>
    theme.colors[color] || color || theme.colors.blue};
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : 'bold')};
  font-size: 16px;
  line-height: 143%;
  text-decoration-line: ${({ underline = true }) =>
    underline ? 'underline' : 'none'};

  transition: color 0.2s ease-in-out;

  :hover {
    text-decoration: none;
    color: #40a9ff;
  }
`;

export const Link16 = styled.a`
  ${setMargin}
  ${commonStyle}
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 143%;
  text-decoration-line: ${({ underline = true }) =>
    underline ? 'underline' : 'none'};
`;

export const Link12 = styled.a`
  ${setMargin}
  ${commonStyle}
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  text-decoration-line: ${({ underline = true }) =>
    underline ? 'underline' : 'none'};
`;

export const LinkFooter = styled.a`
  ${setMargin}
  ${commonStyle}
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: ${({ theme }) => theme.spacings[1]} !important;
  :hover {
    color: white;
    text-decoration: underline;
  }
`;

export const LinkFooterXSM = styled.a`
  ${setMargin}
  ${commonStyle}
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: inline-block !important;
  :hover {
    color: white;
    text-decoration: underline;
  }
`;
