import { fields, createSchema, validate as _validate } from '..';

const { arrayOfStrings, requiredField, booleanFiled } = fields;

const requestAChampion = createSchema({
  skillAreas: arrayOfStrings,
  district: requiredField,
  receiveEmails: booleanFiled,
});

const validate = (data) => {
  return _validate(requestAChampion, data);
};

export default validate;
