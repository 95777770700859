import PropTypes from 'prop-types';

import * as S from './style';
import * as T from '../../Typography';

import { Typography } from 'antd';
import LevelIndicator from '../../LevelIndicator';
import Icon from '../../Icon';

const DynamicTitleSize = ({ title }) => {
  const arr = title.split(' ');
  const isLargeText = arr.find((word) => word.length > 8);
  if (isLargeText) {
    return (
      <T.H2 override="h6" style={{ fontSize: '16.66px' }}>
        {title}
      </T.H2>
    );
  }
  return <T.H3 override="h6">{title}</T.H3>;
};

function ActivityCard({
  to,
  title,
  completionTime,
  difficulty,
  isCompleted,
  color,
  isQuestion,
}) {
  return (
    <S.CardWrapper to={to}>
      <S.ActivityCardTextDiv>
        <Typography.Paragraph
          style={{
            fontFamily: 'Nunito Sans', // doesn't accept in css (i dont know why)
          }}
        >
          {title}
        </Typography.Paragraph>
        {completionTime && (
          <T.BodyB
            color={color}
            display="block"
            style={{
              alignSelf: 'end',
            }}
          >
            {completionTime} minutes
          </T.BodyB>
        )}
      </S.ActivityCardTextDiv>
      <S.ActivityCardColouredDiv
        color={color}
        aria-label={`Difficulty level ${difficulty | 0}`}
      >
        {isQuestion && <Icon icon="questionMark" color="white" />}
        {!isQuestion && (
          <>
            {isCompleted ? (
              <Icon icon="cupWithFlash" strokeColor="white" />
            ) : (
              <LevelIndicator
                level={difficulty || 0}
                size="large"
                color="white"
                bgColor="white"
              />
            )}
            <T.Body16B color="white" mt="1">
              {isCompleted ? 'Completed' : <>Level&nbsp;{difficulty || 0}</>}
            </T.Body16B>
          </>
        )}
      </S.ActivityCardColouredDiv>
    </S.CardWrapper>
  );
}

ActivityCard.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  completionTime: PropTypes.number,
  difficulty: PropTypes.number,
  isCompleted: PropTypes.bool,
  color: PropTypes.string,
};

export default ActivityCard;
