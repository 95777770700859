import React from 'react';
import { handleClickForAnalytics } from '../../helpers';

import * as S from './style';
import { navRoutes } from '../../constants';
import * as T from '../../components/Typography';
import { Grid, Icon } from '../../components';
import InvestorsInPeople from '../../assets/investors-in-people.png';

const { Col, Row } = Grid;

const handleClick = (url) => {
  return handleClickForAnalytics({
    name: 'link_click',
    category: 'link_click',
    action: 'External',
    label: url,
    priority: 'Low',
  });
};

const Footer = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <Row jc="center" mt={4}>
          <T.H4 color="white" mb={4}>
            <Icon height={80} icon={'scoutStack'} color="white" />
          </T.H4>
        </Row>
        <Row>
          <Col w={[4, 4, 3]}>
            <T.H5 color="white" mb={3}>
              The Scouts
            </T.H5>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.NATIONAL_VOLUNTEERING_VACANCIES}
              onClick={() =>
                handleClick(navRoutes.EXTERNAL.NATIONAL_VOLUNTEERING_VACANCIES)
              }
            >
              National volunteering vacancies
            </T.LinkFooter>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.PAID_JOB_VACANCIES}
              onClick={() => handleClick(navRoutes.EXTERNAL.PAID_JOB_VACANCIES)}
            >
              Paid job vacancies
            </T.LinkFooter>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.KEY_POLICIES}
              onClick={() => handleClick(navRoutes.EXTERNAL.KEY_POLICIES)}
            >
              Key policies
            </T.LinkFooter>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.REPORTS}
              onClick={() => handleClick(navRoutes.EXTERNAL.REPORTS)}
            >
              Reports
            </T.LinkFooter>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.BRAND_CENTRE}
              onClick={() => handleClick(navRoutes.EXTERNAL.BRAND_CENTRE)}
            >
              Brand centre
            </T.LinkFooter>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.ACCESSIBILITY_AT_SCOUTS}
              onClick={() =>
                handleClick(navRoutes.EXTERNAL.ACCESSIBILITY_AT_SCOUTS)
              }
            >
              Accessibility at Scouts
            </T.LinkFooter>
          </Col>
          <Col w={[4, 4, 6]} mtM={4}>
            <T.H5 color="white" mb={3}>
              Websites
            </T.H5>
            <Row inner>
              <Col w={[4, 12, 6]}>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.SCOUT_STORE}
                  onClick={() => handleClick(navRoutes.EXTERNAL.SCOUT_STORE)}
                >
                  Scout Store
                </T.LinkFooter>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.SCOUT_ADVENTURES}
                  onClick={() =>
                    handleClick(navRoutes.EXTERNAL.SCOUT_ADVENTURES)
                  }
                >
                  Scout Adventures
                </T.LinkFooter>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.UNITY_INSURANCE_SERVICES}
                  onClick={() =>
                    handleClick(navRoutes.EXTERNAL.UNITY_INSURANCE_SERVICES)
                  }
                >
                  Unity Insurance Services
                </T.LinkFooter>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.WORLD_SCOUTING}
                  onClick={() => handleClick(navRoutes.EXTERNAL.WORLD_SCOUTING)}
                >
                  World Scouting
                </T.LinkFooter>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.WORLD_SCOUT_SHOP}
                  onClick={() =>
                    handleClick(navRoutes.EXTERNAL.WORLD_SCOUT_SHOP)
                  }
                >
                  World Scout Shop
                </T.LinkFooter>
              </Col>
              <Col w={[4, 12, 6]}>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.SCOUTS_SCOTLAND}
                  onClick={() =>
                    handleClick(navRoutes.EXTERNAL.SCOUTS_SCOTLAND)
                  }
                >
                  Scouts Scotland
                </T.LinkFooter>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.SCOUTS_CYMRU}
                  onClick={() => handleClick(navRoutes.EXTERNAL.SCOUTS_CYMRU)}
                >
                  Scouts Cymru
                </T.LinkFooter>
                <T.LinkFooter
                  color="white"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navRoutes.EXTERNAL.SCOUTS_NI}
                  onClick={() => handleClick(navRoutes.EXTERNAL.SCOUTS_NI)}
                >
                  Scouts NI
                </T.LinkFooter>
              </Col>
            </Row>
          </Col>
          <Col w={[4, 4, 3]} mtM={4}>
            <T.H5 color="white" mb={3}>
              Contact us
            </T.H5>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.CONTACT_FOOTER}
              onClick={() => handleClick(navRoutes.EXTERNAL.CONTACT_FOOTER)}
            >
              info.centre@scouts.org.uk
            </T.LinkFooter>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.TEL}
              onClick={() => handleClick(navRoutes.EXTERNAL.TEL)}
            >
              0345 300 1818
              <T.BodySMItalic color="white">
                Open 9am-5pm Monday to Friday
              </T.BodySMItalic>
            </T.LinkFooter>
            <T.LinkFooter
              color="white"
              target="_blank"
              rel="noopener noreferrer"
              href={navRoutes.EXTERNAL.FIND_US}
              onClick={() => handleClick(navRoutes.EXTERNAL.TERMS_OF_USE)}
            >
              Find us...
            </T.LinkFooter>
          </Col>
        </Row>
        <Row mt={4} mb={4}>
          <Col w={[4, 12, 9]}>
            <T.BodyXSM color="white">
              © Copyright The Scout Association 2023. All Rights Reserved.
            </T.BodyXSM>
            <T.BodyXSM color="white">
              Charity numbers: 306101 (England and Wales) and SC038437
              (Scotland).
            </T.BodyXSM>
            <T.BodyXSM color="white">
              Registered address: The Scout Association, Gilwell Park,
              Chingford, London, England E4 7QW
            </T.BodyXSM>
            <T.BodyXSM color="white">
              Website{' '}
              <T.LinkFooterXSM
                color="white"
                target="_blank"
                rel="noopener noreferrer"
                href={navRoutes.EXTERNAL.TERMS_OF_USE}
                onClick={() => handleClick(navRoutes.EXTERNAL.TERMS_OF_USE)}
              >
                Terms of use,
              </T.LinkFooterXSM>{' '}
              <T.LinkFooterXSM
                color="white"
                target="_blank"
                rel="noopener noreferrer"
                href={navRoutes.EXTERNAL.PRIVACY_POLICY}
                onClick={() => handleClick(navRoutes.EXTERNAL.PRIVACY_POLICY)}
              >
                Privacy
              </T.LinkFooterXSM>{' '}
              and{' '}
              <T.LinkFooterXSM
                color="white"
                target="_blank"
                rel="noopener noreferrer"
                href={navRoutes.EXTERNAL.COOKIES}
                onClick={() => handleClick(navRoutes.EXTERNAL.COOKIES)}
              >
                Cookies
              </T.LinkFooterXSM>
            </T.BodyXSM>
          </Col>
          <Col w={[0, 4, 3]}>
            <S.ImgWrapper
              src={InvestorsInPeople}
              alt="Investors in People logo"
            />
          </Col>
        </Row>
      </S.Container>
    </S.Wrapper>
  );
};

export default Footer;
