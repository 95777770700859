import styled from '@emotion/styled';
export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.liteBlue};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;
