import axios from 'axios';
import handleError from './format-error';
import { handleValidationError } from '../validation/validate';

const CHAMPIONS_BASE = '/digital-champions';

const signupNoSSO = async ({ options, form } = {}) => {
  try {
    const { data } = await axios.post(
      `${CHAMPIONS_BASE}/${form.userId}/signup-no-sso`,
      form
    );
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    if (err.statusCode === 422) {
      const { inner } = handleValidationError(error.response.data);
      return { error: err, inner };
    }
    return { error: err };
  }
};

const getDigitalChampions = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${CHAMPIONS_BASE}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getChampionsAwaitingApproval = async ({ options } = {}) => {
  try {
    const { data } = await axios.get(`${CHAMPIONS_BASE}/1/awaiting-approval`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getDigitalChampionById = async ({ options, id } = {}) => {
  try {
    const { data } = await axios.get(`${CHAMPIONS_BASE}/${id}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const updateDigitalChampion = async ({ id, body, options } = {}) => {
  try {
    const data = await axios.patch(`${CHAMPIONS_BASE}/${id}`, body);
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const approveDigitalChampion = async ({ championId, options } = {}) => {
  try {
    const data = await axios.patch(`${CHAMPIONS_BASE}/${championId}/approve`, {
      championId,
    });
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const rejectDigitalChampion = async ({ championId, options } = {}) => {
  try {
    const data = await axios.patch(`${CHAMPIONS_BASE}/${championId}/reject`, {
      championId,
    });
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getDigitalChampions,
  getDigitalChampionById,
  updateDigitalChampion,
  signupNoSSO,
  getChampionsAwaitingApproval,
  approveDigitalChampion,
  rejectDigitalChampion,
};
