import { fields, createSchema, validate as _validate } from '..';

import { roles } from '../../constants';

const { VOLUNTEER } = roles;
const { requiredText } = fields;

const volunteer = createSchema({
  postcode: requiredText,
});

const validate = (data) => {
  if (data.role === VOLUNTEER) return _validate(volunteer, data);
};

export default validate;
