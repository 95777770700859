import styled from '@emotion/styled';
import * as T from '../../Typography';
import { Link } from 'react-router-dom';

export const CardWrapper = styled(Link)`
  box-shadow: ${({ theme }) => theme.shadows.light};
  display: flex;
  border-radius: 5px;
  margin-bottom: ${({ theme }) => theme.spacings[3]};
  transition: 0.5s;
  height: 100%;

  :hover {
    transform: scale(1.05);
  }
`;

export const ActivityCardTextDiv = styled(T.H3)`
  padding: ${({ theme }) => theme.spacings[3]};
  display: grid;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-typography {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 600;
    max-height: 75px;
  }
`;

export const ActivityCardColouredDiv = styled.div`
  background-color: ${({ theme, color }) => theme.colors[color] || color};
  padding: ${({ theme }) => theme.spacings[3]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 96px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows.light};
`;
