import React, { useRef, useEffect, useCallback } from 'react';
import * as S from './style';

const Modal = ({
  open,
  setOpen,
  showCloseButton,
  bgColor,
  onClose,
  onCloseModal,
  children,
}) => {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (onCloseModal) {
      onCloseModal();
    } else if (modalRef.current === e.target) {
      setOpen(false);
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && open) {
        setOpen(false);
      }
    },
    [setOpen, open]
  );

  useEffect(() => {
    document?.addEventListener('keydown', keyPress);
    return () => document?.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => (document.body.style.overflow = 'auto');
  }, [open]);

  return (
    <>
      {open ? (
        <S.Background onClick={closeModal} ref={modalRef}>
          <S.Wrapper>
            <S.ModalWrapper
              open={open}
              bgColor={bgColor}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <S.ModalContent>{children}</S.ModalContent>
              {showCloseButton && (
                <S.CloseModalButton
                  aria-label="Close modal"
                  onClick={() => setOpen(false)}
                >
                  X
                </S.CloseModalButton>
              )}
            </S.ModalWrapper>
          </S.Wrapper>
        </S.Background>
      ) : null}
    </>
  );
};

export default Modal;
