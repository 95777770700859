import { string, number, boolean, array, mixed, object, ref } from 'yup';
import * as errMsgs from './err-msgs';
import { dropdownData } from '../constants';
import './custom-functions';

export const requiredText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const fullName = string()
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .max(20)
  .required(errMsgs.DEFAULT_REQUIRED);

export const email = string()
  .email(errMsgs.INVALID_EMAIL)
  .max(50, errMsgs.TOO_LONG_MAX_50)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const password = string()
  .matches(
    /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)(?=.{8,}).*$/,
    errMsgs.SHORT_PASSWORD
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const confirmPassword = string()
  .oneOf([ref('newPassword'), null], errMsgs.PASSWORDS_NOT_MATCH)
  .required(errMsgs.DEFAULT_REQUIRED);

export const passwordOptional = string()
  .matches(
    /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)(?=.{8,}).*$/,
    errMsgs.SHORT_PASSWORD
  )
  .nullable();

export const loginPassword = string().required(errMsgs.DEFAULT_REQUIRED);

export const postcode = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(6, errMsgs.TOO_SHORT_MIN_5)
  .max(8, errMsgs.TOO_LONG_MAX_7)
  .matches(
    /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
    errMsgs.INVALID_POSTCODE
  );

export const agreedOnTerms = boolean()
  .oneOf([true], errMsgs.SHOULD_AGREE_ON_TERMS)
  .required(errMsgs.DEFAULT_REQUIRED);

export const acknowledgeOfShareData = boolean()
  .oneOf([true], errMsgs.SHOULD_ACKNOWLEDGE_OF_SHARE_DATA)
  .required(errMsgs.DEFAULT_REQUIRED);

export const arrayOfIds = array()
  .of(number())
  .min(1)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const requiredField = string().required(errMsgs.DEFAULT_REQUIRED);

export const optionalFiled = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const arrayOfStrings = array()
  .of(string().required(errMsgs.DEFAULT_REQUIRED))
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const arrayOfStringsOptional = array().of(string()).nullable();

export const otherSkillAreas = string().when(
  'skillAreas',
  (skillAreas, schema) => {
    return skillAreas[0] && skillAreas.includes('OTHERS')
      ? schema.required(errMsgs.DEFAULT_REQUIRED)
      : schema.nullable();
  }
);

export const requestChampionNameIfLoggedIn = mixed().when(
  'isLoggedIn',
  (isLoggedIn, schema) => {
    return !isLoggedIn
      ? string()
          .typeError(errMsgs.DEFAULT_REQUIRED)
          .required(errMsgs.DEFAULT_REQUIRED)
      : schema.oneOf([null]);
  }
);

export const requestChampionEmailIfLoggedIn = mixed().when(
  'isLoggedIn',
  (isLoggedIn, schema) => {
    return !isLoggedIn
      ? string()
          .typeError(errMsgs.DEFAULT_REQUIRED)
          .email(errMsgs.INVALID_EMAIL)
          .max(50, errMsgs.TOO_LONG_MAX_50)
          .required(errMsgs.DEFAULT_REQUIRED)
      : schema.oneOf([null]);
  }
);

export const textArea140w = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .test('140-w', errMsgs.TOO_LONG_WORDS_140, (val) => {
    return val.split(' ').length <= 140;
  });

export const textArea300 = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .max(300, errMsgs.TOO_LONG_MAX_300);

export const textArea140 = string()
  .test(
    'len',
    errMsgs.TOO_LONG_WORDS_140,
    (val) => val && val.trim().split(' ').length <= 140
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const skillTitle = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .max(200, errMsgs.TOO_LONG_MAX_200);

export const skillDescription = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .max(300, errMsgs.TOO_LONG_MAX_300);

export const skillTask = string().nullable().max(50, errMsgs.TOO_LONG_MAX_50);

export const taskSearchInput = string().when('tool', (tool, schema) =>
  tool === dropdownData.A_DIGITAL_TOOL
    ? schema.required(errMsgs.DEFAULT_REQUIRED)
    : schema.nullable()
);

export const levelField = number().when('isDraft', {
  is: false,
  then: number()
    .typeError(errMsgs.NUMBER_REQUIRED)
    .min(1)
    .max(4)
    .required(errMsgs.DEFAULT_REQUIRED),
  otherwise: number()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      if (!originalValue || !originalValue.trim()) {
        return null;
      }
      return originalValue;
    })
    .nullable(true),
});

export const timeField = number().when('isDraft', {
  is: false,
  then: number()
    .typeError(errMsgs.NUMBER_REQUIRED)
    .min(1)
    .max(1000)
    .required(errMsgs.DEFAULT_REQUIRED),
  otherwise: number()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      if (!originalValue || !originalValue.trim()) {
        return null;
      }
      return originalValue;
    })
    .nullable(true),
});

export const timeFieldHr = number().when(
  ['isDraft', 'completionTimeMinClone'],
  {
    is: (...args) => args[0] === false && !args[1],
    then: number()
      .typeError(errMsgs.NUMBER_REQUIRED)
      .min(1)
      .max(1000)
      .required(errMsgs.DEFAULT_REQUIRED),
    otherwise: number()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        if (!originalValue || !originalValue.trim()) {
          return null;
        }
        return originalValue;
      })
      .nullable(true),
  }
);

export const timeFieldMin = number().when(['isDraft', 'completionTimeHr'], {
  is: (...args) => args[0] === false && !args[1],
  then: number()
    .typeError(errMsgs.NUMBER_REQUIRED)
    .min(1)
    .max(1000)
    .required(errMsgs.DEFAULT_REQUIRED),
  otherwise: number()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      if (!originalValue || !originalValue.trim()) {
        return null;
      }
      return originalValue;
    })
    .nullable(true),
});

export const imageKey = string().when('titleImage', {
  is: (titleImage) => !!titleImage,
  then: string().required('required field'),
  otherwise: string().nullable(),
});

export const TitleField = string()
  .max(200, errMsgs.TOO_LONG_MAX_200)
  .required(errMsgs.DEFAULT_REQUIRED);

export const creatorField = string().when('isDraft', {
  is: false,
  then: string()
    .max(50, errMsgs.TOO_LONG_MAX_50)
    .required(errMsgs.DEFAULT_REQUIRED),
  otherwise: string().nullable(true),
});

export const urlRequired = string().when('isDraft', {
  is: false,
  then: string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#.-]+)*\/?(\?[a-zA-Z0-9-_.-]+=[a-zA-Z0-9-%?&=.-]+&?)?$/,
      {
        message: errMsgs.INVALID_LINK,
      }
    )
    .required(errMsgs.DEFAULT_REQUIRED),
  otherwise: string().nullable(true),
});

export const descriptionField = string().when('isDraft', {
  is: false,
  then: string()
    .max(500, errMsgs.TOO_LONG_MAX_500)
    .required(errMsgs.DEFAULT_REQUIRED),
  otherwise: string().nullable(true),
});

export const hideSkill = boolean().required(errMsgs.DEFAULT_REQUIRED);
export const hideActivity = boolean().required(errMsgs.DEFAULT_REQUIRED);
export const isDraft = boolean().required(errMsgs.DEFAULT_REQUIRED);
export const booleanFiled = boolean().required(errMsgs.DEFAULT_REQUIRED);
export const arrayOfObjects = array().of(object()).min(0).max(5);
export const phoneNumber = string()
  .phone()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .max(20, errMsgs.INVALID_PHONE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalPhoneNumber = string().when((value, schema) => {
  if (value) {
    return schema.phone().typeError(errMsgs.INVALID_PHONE);
  }
  return schema.nullable();
});
