const GENERAL = {
  HOME: '/',
  NOT_FOUND: '/not-found',
  MOCK_SSO_PAGE: '/sso/mock_sso_page',
  SSO_LOGIN: '/sso/login',
  LINK_COMPASS_ACCOUNT: '/connect-my-compass-account',
  SSO_CONNECT_COMPASS_LOGIN: '/sso/connect-compass/login',
  SSO_CONNECT_COMPASS_SUCCESS: '/sso/connect-compass/success',
  LOGIN: '/login',
  UNAUTHORIZED: '/unauthorized',
  SEARCH: '/search',
  LOGOUT: '/logout',
  SKILLS: '/skills',
  SUPPORT: '/support',
  ASSESSMENT: '/digital-test',
  ASSESSMENT_STEP: '/digital-test/:step',
  HELP_OTHERS: '/help-others',
  SKILL: '/skills/:id',
  SKILL_EDIT: '/skills/edit/:id',
  ACTIVITY: '/activity/:id',
  ACTIVITY_COMPLETED: '/skill/:skillId/activity/:activityId/success/:step',
  SKILL_ACTIVITY: '/skills/:skillId/activity/:activityId',
  SETTINGS: '/settings',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_SENT: '/password-sent',
  SET_PASSWORD: '/set-password/:token',
  SET_PASSWORD_DONE: '/set-password-done',
  SKILL_AREA: '/skill-area/:id',
  REQUEST_DIGITAL_CHAMPION: '/request-digital-champion',
  REQUEST_DIGITAL_CHAMPION_SUCESS: '/request-digital-champion/success',
  LANDING: '/what-is-a-digital-champion',
  MEET_CHAMPIONS: '/meet-digital-champions',
  RESOURCES_FOR_HELPING: '/resources-for-helping-volunteers',
  DIGITAL_CHAMPIONS: '/digital-champions',
  DIGITAL_CHAMPION_DETAILS: '/digital-champions/:id',
  RESOURCE_SPACE: '/resource-space',
  GUIDE_TO_BEING_A_DIGITAL_CHAMPION: '/guide-to-being-a-digital-champion',
  SUGGEST_ACTIVITY: '/suggest-activity',
  ONBOARDING_SIGNUP_DIGITAL_CHAMPION: '/onboarding-signup-digital-champion',
  QUESTION_VIA_SKILL_OR_ACTIVITY: '/ask-question',
  THANKS_FOR_ASK_QUESTION: '/thanks-for-ask-question',
  QUESTION_DETAILS: '/question/:id',
  ASSESSMENT_LANDING: '/assessment',
};

const VOLUNTEER = {
  SIGNUP: '/signup',
  // BRING BACK WITH SSO
  // SIGNUP: '/sso/signup',
  CONTINUE_SIGNUP: '/sso/continue-signup',
  WELCOME: '/welcome',
  DASHBOARD: '/dashboard',
  ENTER_POSTCODE: '/postcode',
  RESULTS: '/result',
  SETTINGS: '/settings',
  RECOMMENDED_SKILLS: '/recommended-skills',
  SKILLS: '/skills',
  UPDATE: '/update',
  PROGRESS: `/skill/:skillId/activity/:activityId/progress`,
  CHECK_IN: '/check-in',
  CHECK_IN_ASSESSMENT: '/check-in/:step',
  THANKS_FOR_ANSWER_QUESTION: `/thanks-for-answer-question`,
  SUCCESS_SOLVED_QUESTION: '/success-solve-question',
  EXPERT_BADGE: '/badge/:skillAreaId/:skillAreaTitle',
  THANKS_FOR_SEND_REPLY: '/thanks-for-send-reply',
  BOOK_MARKED_SKILLS: '/bookmarked-skills',
};

const DCBase = '/digital-champion';

const DIGITAL_CHAMPION = {
  SIGNUP: `/sso${DCBase}/signup`,
  CONTINUE_SIGNUP: `/sso${DCBase}/continue-signup`,
  // SIGNUP_SUCCESS: `/sso${DCBase}/signup-success`,
  SIGNUP_SUCCESS: `${DCBase}/signup-success`,
  INTRO: `${DCBase}/intro/:id`,
  DASHBOARD: `${DCBase}/dashboard`,
  MY_ASSIGNED_REQUESTS: `${DCBase}/my-assigned-requests`,
  REQUEST_DETAILS: `${DCBase}/request/:id`,
  ASSIGNED_SUCCESS: `${DCBase}/assigned-success/:id`,
  UNASSIGN: `${DCBase}/request/:id/unassign`,
  DIGITAL_CHAMPIONS: `${DCBase}${GENERAL.DIGITAL_CHAMPIONS}`,
  DIGITAL_CHAMPION_DETAILS: `${DCBase}${GENERAL.DIGITAL_CHAMPION_DETAILS}`,
  RESOURCE_SPACE: `${DCBase}${GENERAL.RESOURCE_SPACE}`,
  GUIDE_TO_BEING_A_DIGITAL_CHAMPION_MAIN: `${DCBase}${GENERAL.GUIDE_TO_BEING_A_DIGITAL_CHAMPION}`,
  GUIDE_TO_BEING_A_DIGITAL_CHAMPION_SUB_PAGE: `${DCBase}${GENERAL.GUIDE_TO_BEING_A_DIGITAL_CHAMPION}/:slug`,
  SETTINGS: `${DCBase}/settings`,
  FIND_SKILLS: `${DCBase}/find-skills/:q?`,
  SUGGEST_ACTIVITY: `${DCBase}${GENERAL.SUGGEST_ACTIVITY}`,
  SUGGEST_ACTIVITY_SUCCESS: `${DCBase}${GENERAL.SUGGEST_ACTIVITY}/success`,
  TRAINING_PAGES: `${DCBase}/training`,
  TRAINING_PAGE: `${DCBase}/training/:id`,
  ALL_QUESTIONS: `${DCBase}/all-questions`,
  SOLVED_QUESTIONS: `${DCBase}/solved-questions`,
  BANK_OF_QUESTIONS: `${DCBase}/bank-of-questions/:q?`,
  QUESTION_DETAILS: `${DCBase}/question/:id`,
  ANSWER_QUESTION: `${DCBase}/answer-question/:id`,
  THANKS_FOR_ANSWER_QUESTION: `${DCBase}/thanks-for-answer-question`,
};

const HQBase = '/hq';

const HQ = {
  HOME: HQBase,
  SIGNUP: `${HQBase}/signup`,
  WELCOME: `${HQBase}/welcome`,
  DASHBOARD: `${HQBase}/dashboard`,
  VOLUNTEERS: `${HQBase}/volunteers`,
  MANAGE_SKILLS: `${HQBase}/manage-skills`,
  PROGRESS: `${HQBase}/progress`,
  MANAGE_VOLUNTEERS: `${HQBase}/manage-volunteers`,
  SKILLS_PROGRESS: `${HQBase}/skills-progress`,
  INDIVIDUAL_SKILL: `${HQBase}/skills/:id`,
  EDIT_INDIVIDUAL_SKILL: `${HQBase}/skills/:id/edit`,
  CREATE_SKILL: `${HQBase}/skills/new`,
  EDIT_ACTIVITY: `${HQBase}/activities/:id/edit`,
  ADD_ACTIVITY: `${HQBase}/activities/new`,
  SUCCESS_ADD_ACTIVITY: `${HQBase}/success-add-activity/:id`,
  SUCCESS_EDIT_ACTIVITY: `${HQBase}/success-edit-activity/:id`,
  INDIVIDUAL_SKILL_AREA: `${HQBase}/manage-skills/:id`,
  EDIT_INDIVIDUAL_SKILL_AREA: `${HQBase}/manage-skills/:id/edit`,
  CREATE_SKILL_AREA: `${HQBase}/manage-skills/new`,
  LOGIN: `${HQBase}${GENERAL.LOGIN}`,
  QUESTIONS: `${HQBase}/questions`,
  ALL_QUESTIONS: `${HQBase}/all-questions`,
  ANSWER_QUESTION: `${HQBase}/answer-question/:id`,
  QUESTION_DETAILS: `${HQBase}/question/:id`,
  THANKS_FOR_ANSWER_QUESTION: `${HQBase}/thanks-for-answer-question`,
  SOLVED_QUESTIONS: `${HQBase}/solved-questions`,
  REQUEST_DETAILS: `${HQBase}/request/:id`,
  SUGGESTED_ACTIVITIES: `${HQBase}/suggested-activities`,
  REVIEW_SUGGESTED_ACTIVITIES: `${HQBase}/suggested-activities/:id`,
  REVIEW_SUGGESTED_ACTIVITIES_SUCCESS: `${HQBase}/suggested-activities/success`,
};

const EXTERNAL = {
  TERMS_OF_USE: 'https://www.scouts.org.uk/about-us/policy/terms-conditions/',
  PRIVACY_POLICY:
    'https://www.scouts.org.uk/about-us/policy/data-protection-policy/',
  HELP: '/help', // in search page
  TERMS_AND_CONDITIONS:
    'https://www.scouts.org.uk/about-us/policy/terms-conditions/',
  COOKIES: 'https://www.scouts.org.uk/about-us/policy/cookie-policy',
  SCOUTS: 'https://www.scouts.org.uk/',
  CONTACT: 'mailto:report@scouts.org.uk',
  CONTACT_FOOTER: 'mailto:info.centre@scouts.org.uk',
  TEL: 'tel:0345 300 1818',
  FIND_US: 'https://goo.gl/maps/PpcEMrfYgH32',
  SSO_AUTH:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_SSO_AUTH
      : `${GENERAL.MOCK_SSO_PAGE}`,
  SAFEGUARDING_POLICY:
    'https://www.scouts.org.uk/about-us/policy/safeguarding-policy-and-procedures/',
  SAFEGUARDING: 'mailto:safeguarding@scouts.org.uk',
  YELLOW_CARD:
    'https://www.scouts.org.uk/volunteers/staying-safe-and-safeguarding/safe-scouting-cards/young-people-first-yellow-card/',
  NATIONAL_VOLUNTEERING_VACANCIES:
    'https://www.scouts.org.uk/volunteer/volunteer-vacancies/',
  PAID_JOB_VACANCIES: 'https://www.scouts.org.uk/about-us/jobs/',
  KEY_POLICIES: 'https://www.scouts.org.uk/por/2-key-policies/',
  REPORTS: 'https://www.scouts.org.uk/about-us/reports/',
  BRAND_CENTRE: 'https://www.scouts.org.uk/brand',
  ACCESSIBILITY_AT_SCOUTS: 'https://www.scouts.org.uk/digitalaccessibility',
  SCOUT_STORE: 'https://shop.scouts.org.uk/',
  SCOUT_ADVENTURES: 'https://www.scoutadventures.org.uk/',
  UNITY_INSURANCE_SERVICES:
    'https://www.unityinsuranceservices.co.uk/scout-insurance',
  WORLD_SCOUTING: 'https://www.scout.org/',
  WORLD_SCOUT_SHOP: 'https://www.worldscoutshops.com/',
  SCOUTS_SCOTLAND: 'https://www.scouts.scot/',
  SCOUTS_CYMRU: 'http://www.scoutscymru.org.uk/',
  SCOUTS_NI: 'http://scoutsni.org/',
};

// VOLUNTEER
const VOLUNTEER_LOGGED_OUT = [
  { title: 'Home', to: GENERAL.HOME },
  { title: 'Test your digital skills', to: GENERAL.ASSESSMENT },
  { title: 'Get help with a task', to: GENERAL.SEARCH },
  {
    title: 'Reach out to a Digital Champion',
    to: GENERAL.REQUEST_DIGITAL_CHAMPION,
  },
  { title: 'Support others', to: GENERAL.LANDING },
];

const VOLUNTEER_LOGGED_OUT_AUTH = [{ title: 'Log in', login: true }];

const VOLUNTEER_LOGGED_IN = [
  { title: 'Home', to: GENERAL.HOME },
  { title: 'My dashboard', to: VOLUNTEER.DASHBOARD },
  { title: 'Get help with a task', to: GENERAL.SEARCH },
  {
    title: 'Reach out to a Digital Champion',
    to: GENERAL.REQUEST_DIGITAL_CHAMPION,
  },
  { title: 'Support others', to: GENERAL.LANDING },
];

const VOLUNTEER_LOGGED_IN_AUTH = [{ title: 'Log out', logout: true }];

// DIGITAL CHAMPION
const DIGITAL_CHAMPION_LOGGED_IN = [
  { title: 'Requests for help', to: DIGITAL_CHAMPION.DASHBOARD },
  { title: 'Resource Centre', to: DIGITAL_CHAMPION.RESOURCE_SPACE },
  {
    title: 'View all Digital Champions',
    to: GENERAL.DIGITAL_CHAMPIONS,
  },
  { title: 'View digital tool', to: GENERAL.HOME },
  { title: 'Contact Scouts', to: EXTERNAL.CONTACT, external: true },
];

const DIGITAL_CHAMPION_LOGGED_IN_AUTH = [
  { title: 'My account', to: DIGITAL_CHAMPION.SETTINGS },
  { title: 'Log out', logout: true },
];

// IF DIGITAL CHAMPION LOGGED OUT, THEY HAVE SAME VIEW AS LOGGED OUT VOLUNTEER

// HQ / ADMIN
const HQ_USER_LOGGED_IN = [
  { title: 'Home', to: HQ.DASHBOARD },
  { title: 'My volunteers', to: HQ.VOLUNTEERS },
  { title: 'Progress', to: HQ.SKILLS_PROGRESS },
  { title: 'Manage skills', to: HQ.MANAGE_SKILLS },
  { title: 'View digital tool', to: GENERAL.HOME },
];

const HQ_USER_LOGGED_IN_AUTH = [{ title: 'Log out', logout: true }];

const HQ_USER_LOGGED_OUT = [{ title: 'Home', to: HQ.HOME }];

const HQ_USER_LOGGED_OUT_AUTH = [{ title: 'Log in', login: true }];

const NAV_ROUTES = {
  volunteer: {
    LOGGED_IN: [...VOLUNTEER_LOGGED_IN],
    LOGGED_IN_AUTH: [...VOLUNTEER_LOGGED_IN_AUTH],
    LOGGED_OUT: [...VOLUNTEER_LOGGED_OUT],
    LOGGED_OUT_AUTH: [...VOLUNTEER_LOGGED_OUT_AUTH],
  },
  digitalChampion: {
    LOGGED_IN: [...DIGITAL_CHAMPION_LOGGED_IN],
    LOGGED_IN_AUTH: [...DIGITAL_CHAMPION_LOGGED_IN_AUTH],
  },
  HQUser: {
    LOGGED_IN: [...HQ_USER_LOGGED_IN],
    LOGGED_IN_AUTH: [...HQ_USER_LOGGED_IN_AUTH],
    LOGGED_OUT: [...HQ_USER_LOGGED_OUT],
    LOGGED_OUT_AUTH: [...HQ_USER_LOGGED_OUT_AUTH],
  },
};

export { GENERAL, VOLUNTEER, DIGITAL_CHAMPION, HQ, EXTERNAL, NAV_ROUTES };
