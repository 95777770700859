import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import { NAV_ROUTES } from '../../constants/nav-routes';
import userRoles from '../../constants/roles';
import { useAuth } from '../../context/auth';
import { useSSO } from '../../context/SSO';
import { handleClickForAnalytics } from '../../helpers';

import * as S from './style';

const LinkComponent = ({ to, key, external, onClick, title }) => {
  if (external) {
    return (
      <S.Li>
        <a className="menu__item" href={to} target="_blank" rel="noreferrer">
          {title}
        </a>
      </S.Li>
    );
  }

  if (to) {
    return (
      <S.Li>
        <NavLink
          key={key}
          exact
          to={to}
          className="menu__item"
          activeClassName={'menu__item__selected'}
          external={external}
          onClick={onClick}
        >
          {title}
        </NavLink>
      </S.Li>
    );
  } else {
    return (
      <S.Li>
        <S.ButtonLink key={key} className="menu__item" onClick={onClick}>
          {title}
        </S.ButtonLink>
      </S.Li>
    );
  }
};
const Menu = ({ closeDrawer, fullScreen, sideBar }) => {
  const { volunteer, HQUser, digitalChampion } = NAV_ROUTES;

  // To test navbar outside of logging in
  // Uncomment this line and comment out the user part in section below
  // const user = { role: userRoles.VOLUNTEER };
  const { user } = useAuth();
  const { logout: handleLogout, login: handleLogin } = useSSO();

  let routes = volunteer.LOGGED_OUT;
  let authRoutes = volunteer.LOGGED_OUT_AUTH;

  const history = useHistory();

  const isHQ = history.location.pathname.includes('/hq');
  if (user.role === userRoles.VOLUNTEER) {
    routes = volunteer.LOGGED_IN;
    authRoutes = volunteer.LOGGED_IN_AUTH;
  } else if (user.role === userRoles.DIGITAL_CHAMPION) {
    routes = digitalChampion.LOGGED_IN;
    authRoutes = digitalChampion.LOGGED_IN_AUTH;
  } else if (user.role === userRoles.HQ) {
    routes = HQUser.LOGGED_IN;
    authRoutes = HQUser.LOGGED_IN_AUTH;
  } else if (!isHQ) {
    routes = volunteer.LOGGED_OUT;
    authRoutes = volunteer.LOGGED_OUT_AUTH;
  } else if (isHQ) {
    routes = HQUser.LOGGED_OUT;
    authRoutes = HQUser.LOGGED_OUT_AUTH;
  }

  const handleClick = (logout, login) => {
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
    if (logout) {
      handleLogout();
    } else if (login) {
      handleLogin();
    }
  };

  const onClick = (e, title, logout, login) => {
    handleClick(logout, login);
    handleClickForAnalytics({
      name: 'navigation_click',
      category: 'Navigation_Click',
      action: title,
      priority: 'Low',
    });
  };

  const styledTopics = [];
  const styledAuthTopics = [];
  routes.forEach(({ title, to, logout, external, login }, index) =>
    styledTopics.push(
      <LinkComponent
        to={to}
        key={index}
        logout={logout}
        login={login}
        external={external}
        onClick={(e) => onClick(e, title, logout, login)}
        title={title}
      />
    )
  );

  authRoutes.forEach(({ title, to, logout, external, login }, index) =>
    styledAuthTopics.push(
      <LinkComponent
        to={to}
        key={index}
        logout={logout}
        login={login}
        external={external}
        onClick={(e) => onClick(e, title, logout, login)}
        title={title}
      />
    )
  );

  if (fullScreen)
    return (
      <S.FullScreenMenu color="black">
        {styledTopics} <S.FullScreenDivider /> {styledAuthTopics}
      </S.FullScreenMenu>
    );

  return (
    <S.Menu sideBar={sideBar}>
      {styledTopics}
      <S.Li>
        <S.Divider />
      </S.Li>
      {styledAuthTopics}
    </S.Menu>
  );
};

export default Menu;
