import * as S from './style';
import Modal from '../Modal';
import Button from '../Button';
import Icon from '../Icon';
import * as T from '../Typography';

const SSOModals = ({
  isErrorModalOpen,
  setIsErrorModalOpen,
  errorModalTitle,
  errorModalContent,
  enableLoginButton,
  handleLogin,
}) => {
  return (
    <>
      {/* ERROR MODAL */}
      <Modal open={isErrorModalOpen} setOpen={setIsErrorModalOpen}>
        <S.ModalContent>
          <S.Circle color="lighterRed">
            <Icon icon="cross" color="red" />
          </S.Circle>
          <T.H2 override="h5" ta="center" mt={4}>
            {errorModalTitle}
          </T.H2>
          <T.Body16 ta="center" mt="2" mb="5">
            {errorModalContent}
          </T.Body16>
          {enableLoginButton && (
            <Button mb={1} handleClick={handleLogin}>
              Login
            </Button>
          )}
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default SSOModals;
