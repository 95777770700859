import { fields, createSchema, validate as _validate } from '..';

const signupChampion = createSchema({
  strongestSkillAreas: fields.arrayOfStrings,
  district: fields.requiredField,
  receiveEmails: fields.booleanFiled,
  acknowledgeOfShareDetails: fields.acknowledgeOfShareData,
});

const upgradeToChampion = createSchema({
  strongestSkillAreas: fields.arrayOfStrings,
  district: fields.requiredField,
  receiveEmails: fields.booleanFiled,
  acknowledgeOfShareDetails: fields.acknowledgeOfShareData,
});

const validate = (data) => {
  if (data.upgradeToChampion) {
    return _validate(upgradeToChampion, data);
  }
  return _validate(signupChampion, data);
};

export default validate;
