import styled from '@emotion/styled';
import { css } from '@emotion/react';

const commonMenuStyles = ({
  theme: {
    spacings,
    colors,
    constants: {
      navBar: { active },
    },
  },
  color = 'white',
}) => css`
  .menu__item {
    background-color: none;
    color: ${colors[color]};
    padding: 0 !important;
    font-weight: normal;
    margin-top: 0 !important;
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  ${commonMenuStyles}
  ${({ theme }) => theme.horizontalPaddings};
  padding: ${({ sideBar }) => sideBar && 0};

  .menu__item {
    font-size: 18.66px;
    line-height: 143%;
  }

  a:not(:last-child) {
    margin-bottom: ${({ theme: { spacings } }) => `${spacings[4]} !important`};
  }

  .menu__item__selected {
    font-weight: bold;

    &:after {
      margin-left: ${({
        theme: {
          constants: {
            navBar: { active },
          },
        },
      }) => active};
      content: ' ';
      width: ${({
        theme: {
          constants: {
            navBar: { active },
          },
        },
      }) => active};
      height: ${({
        theme: {
          constants: {
            navBar: { active },
          },
        },
      }) => active};
      display: inline-block;
      background: ${({ theme: { colors } }) => colors.white};
      transform: rotate(45deg);
    }


`;

export const Divider = styled('div')`
  width: ${({
    theme: {
      constants: {
        navBar: { divider },
      },
    },
  }) => divider.width};
  height: ${({
    theme: {
      constants: {
        navBar: { divider },
      },
    },
  }) => divider.height};
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-top: ${({
    theme: {
      constants: {
        navBar: { divider },
      },
    },
  }) => divider.top};
  margin-bottom: ${({
    theme: {
      constants: {
        navBar: { divider },
      },
    },
  }) => divider.bottom};
`;

export const FullScreenMenu = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  ${commonMenuStyles}
  margin-bottom: 0;

  .menu__item {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 0;
  }

  a {
    margin-right: ${({ theme: { spacings } }) => `${spacings[4]} !important`};
  }

  .menu__item__selected {
    font-weight: 900;
    margin-bottom: 0;
  }
`;

export const FullScreenDivider = styled.li`
  margin-right: ${({ theme: { spacings } }) => `${spacings[4]} !important`};
  width: 1px;
  height: 50px;
  background-color: ${({ theme: { colors } }) => colors.gray1};
  list-style: none;
`;

export const Ul = styled.li`
  list-style: none;
  all: inherit;
  padding-left: 0;
`;

export const Li = styled.li`
  list-style: none;
  all: inherit;
  padding-left: 0;

  a {
    text-decoration: none;
  }
`;

export const ButtonLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: none;
  text-align: left;
`;
